.container-small-loader {
    top: 0;
    left: 0;
    position: absolute;
    width:100%;
    height:100%;
    /* background-color: #94949420; */
    display: flex;
    justify-content: center;
  }
  
  .container-small-loader-small{
    top: 0;
    left: 0;
    position: absolute;
    width:100%;
    height:20%;
    /* background-color: #94949420; */
    display: flex;
    justify-content: center;
  }
  
  .loader-small {
    text-align: center;
    vertical-align: middle;
    align-self: center;
    /* position: relative; */
    display: flex;
  }
  
  .loader-small span {
    display: block;
    width: 10px;
    height: 10px;
    background: #eee;
    border-radius: 50%;
    margin: 0 5px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  }
  
  .loader-small span:nth-child(2) {
    background: #f07e6e;
  }
  
  .loader-small span:nth-child(3) {
    background: #84cdfa;
  }
  
  .loader-small span:nth-child(4) {
    background: #5ad1cd;
  }
  
  .loader-small span:not(:last-child) {
    animation: animate 1.5s linear infinite;
  }
  
  @keyframes animate {
    0% {
      transform: translateX(0);
    }
  
    100% {
      transform: translateX(30px);
    }
  }
  
  .loader-small span:last-child {
    animation: jumps 1.5s ease-in-out infinite;
  }
  
  @keyframes jumps{
    0% {
      transform: translate(0, 0);
    }
    10% {
      transform: translate(5px, -5px);
    }
    20% {
      transform: translate(10px, 5px);
    }
    30% {
      transform: translate(20px, -35px);
    }
    70% {
      transform: translate(-90px, -35px);
    }
    80% {
      transform: translate(-80px, 5px);
    }
    90% {
      transform: translate(-75px, -3px);
    }
    100% {
      transform: translate(-55px, 0);
    }
  }
  