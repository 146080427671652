//
//

.disappearing-button-container-page-top-right {
  position: absolute;
  right: 300px;
  top: 85px;
  text-align: 'center';
  z-index: 10;

  @media (max-width: 850px) {
    display: none;
  }
}

.disappearing-button-container-page-top-center {
  position: absolute;
  right: 300px;
  top: 85px;

  z-index: 10;

  @media (max-width: 850px) {
    display: none;
  }
}

.appearing-button-container-page-bottom-right {
  position: absolute;
  bottom: 80px;
  right: 50px;
  text-align: 'center';
  z-index: 10;

  @media (min-width: 850px) {
    display: none;
  }
}

.invalid {
  border-color: 1px solid red;
}

.details_card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-top: -35px;
  margin-left: 1rem;
  // margin-left: 3rem;
}

.details_card_child {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-left: 1rem;
  margin-right: 1rem;
  // padding: 0px 555px 0px 42px
  // padding: 0px 45px 0px 50px;

  transform: scaleY(1);




}

.details_card_image {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.details_card__Togglebutton {
  display: flex;
  justify-content: flex-end;

}

.detail__card__rightLogos {
  width: 15%;
  height: 200px;
  min-width: 200px;
}


.detail_item {
  width: 100%;
  height: 550px;
  padding: 10px;
  background-color: #fff;
  transition: height 500ms ease;
  overflow: hidden;
}

.detail_none {
  height: 230px;
  margin-bottom: 20px;


}

.gtcc_detail_none {
  height: 100%;
  margin-bottom: 20px;


}

.detail_showing {
  height: 560px;
  display: block !important;
  padding: 10px;
}

.gtcc__detail_showing {
  height: 420px;
  display: block !important;
  padding: 10px;
}

.card__tiles {

  border-radius: 0.6em;
  box-shadow: 3px 3px 11px 0px rgba(196, 196, 196, 1);
  transition: all ease 200ms;
  animation: fadeInUp 800ms;

}

.card__tiles:hover {
  transform: scale(1.02);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.card__tiles__image {
  border-radius: 0.6em;
  cursor: pointer;
  transition: all ease 200ms;
}

.card__tiles__image:hover {
  transform: scale(1.04);
}


.card__tile__imagegrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;

  @media (max-width: 850px) {
    display: flex;
    flex-wrap: wrap;
  }

}


.entity__image__fade-in {
  animation: fadeIn 5s;
  transition: all 500ms ease;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.testing {
  display: block !important;
  transform: scaleY(1);

}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0)
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}


.rmsc.multiselect__style {

  z-index: 9999 !important;

}

.terms_p {
  font-size: "13px";
  font-weight: "bold";
  text-decoration: underline;
}



.gatePage__circle {
  border: solid 1px;
  background: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  line-height: 0;
  position: relative;

}




//cameraView
.camera_view_card {
  background-color: #D9D9D9;
  width: 200px;
  height: 110px;

  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: 0.3s;
  }

}



.camera_view_grid-container {
  display: grid;
  grid-template-columns: auto auto;
  background-color: #D9D9D9;
  width: 180px;
  height: 90px;
  margin-top: 10px;
  margin-left: 10px;
  grid-gap: 3px;

}

.camera_view_grid-container2 {
  display: grid;
  grid-template-columns: auto auto auto;
  background-color: #D9D9D9;
  width: 180px;
  height: 90px;
  margin-top: 10px;
  margin-left: 10px;
  grid-gap: 3px;

}

.camera_view_grid-item {
  background-color: white;
  border-radius: 3px;

}

.camera_select_card {
  width: 210px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 7px;
  border: 1px solid#cbcbcb;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-top: 7px;

  &:hover {


    background-color: #e2e2e2;
  }
}

.camera_view_container1 {




  // padding-left: 50px;
  // padding-right:50px ;



}



.camera_view_container2 {
  // width:100% ;
  // min-height: 100vh;
  // height:83vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
  column-gap: 10px;

}



.camera_view_container3 {
  // width:100% ;
  // min-height: 100vh;
  // height:83vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 2px;
  column-gap: 2px;
  // width:100% ;
  // height:83vh;
  // grid-template-columns: auto auto auto;
  // grid-gap: 5px;
  // padding-left: 50px;
  // padding-right:50px ;
  // display: grid;


}


//Filter
.site_report_filter {
  display: flex;
  justify-content: flex-start;

  @media (max-width: 850px) {
    width: 600px;
    flex-wrap: wrap;
  }

  @media (max-width: 500px) {
    flex-direction: column;

  }


}

.site_report_filter_input {
  width: 200px;
  margin-left: 10px;

  @media (max-width: 850px) {
    margin-top: 10px;
  }

  @media (max-width: 500px) {
    width: 300px;


  }

}

.find_camera_search {
  width: 250px;
  height: 38px;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid;
  border-color: #cbcbcb;


}


.camera-view_popup_container {
  width: 230px;
  height: 300px;
  padding:10px 10px 10px 10px;
  border-radius: 5px;
  background-color:#F1F5F7 ;
  overflow-y: scroll;
 overflow-x: hidden;

  // &::-webkit-scrollbar {
  //   display: none;
  // }
}

.camera-view_popup_container::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.camera-view_popup_container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(223, 223, 223); 
  border-radius: 10px;
}
 
/* Handle */
.camera-view_popup_container::-webkit-scrollbar-thumb {
  background:#d8d8d8; 
  border-radius: 10px;
}
.camera-view_popup_container {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}





//camera-view-count

.camera-view-bottom-left {
  position: absolute;
  margin-left: 10px;
  width: 200px;
  margin-top: 15px;
  height: 85px;
  background-color: rgba(255, 255, 255, 0.372);
  border-radius: 10px;
  color: white;

  &:hover {
    transform: scale(1.02);
    transition: 0.3s;


  }
}

.camera-view-top1 {
  border-radius: 6px;
  background-color: rgba(8, 8, 8, 0.372);
  width: 180px;
  margin-left: 10px;
  height: 75px;

}


.camera-view-bottom-left2 {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.372);
  border-radius: 10px;
  color: white;
  width: 120px;
  height: 55px;
  margin-top: 10px;
  margin-left: 5px;
 

  &:hover {
    transform: scale(1.02);
    transition: 0.3s;

  }

}

.camera-view-top2 {
  border-radius: 6px;
  width: 110px;
  margin-left: 5px;
  height: 50px;
  margin-top: 2px;
  background-color: rgba(8, 8, 8, 0.372);
}


.camera-view-bottom-left3 {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.372);
  border-radius: 10px;
  color: white;
  width: 100px;
  height: 50px;
  margin-top: 5px;
  margin-left: 5px;

  &:hover {
    transform: scale(1.02);
    transition: 0.3s;

  }

}

.camera-view-top3 {
  border-radius: 6px;
  width: 90px;
  margin-left: 5px;
  height: 45px;
  background-color: rgba(8, 8, 8, 0.372);
  margin-top: 3px;

}

.search-button {

  margin-bottom: 2px;
  border-radius: 5px;
  padding: 14px 14px;
  border: 1px solid #7a7a7a;
}


.abaci-camera-card {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 90px;
  // max-height: 104px;
  background-color: white;
  justify-content: space-between;
  cursor: pointer;
  flex-wrap: wrap;
  padding: 20px;
  border: 1px solid rgb(220, 218, 218);
  margin-bottom: 1px;

  &:hover {
    transform: scale(1.0005);
    transition: 0.2s;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1)
  }







}


.serial_number {
  display: block;

  @media (max-width: 1200px) {
    display: none;

  }
}

.abaci-scheduler-card-name {
  display: none;

  @media (max-width: 490px) {
    display: block;


  }
}

// .abaci-scheduler-card-content2 {
//   @media (max-width: 1270px) {
//     display: none;

//   }

//   @media (max-width: 992px) {
//     display: none;
//   }

//   @media (max-width: 600px) {
//     display: none;
//   }
// }

.scheduler-resolution-content {
  display: none;

  @media (max-width: 700px) {
    display: block;
  }

  @media (max-width: 490px) {
    display: flex;
    justify-content: center;
  }
}

.scheduler-resolution-content2 {
  margin-top: 5px;
  position: relative;
  right: 30%;

  @media (max-width: 1610) {
    right: 27%;
    position: relative;
  }


}

.scheduler-card-action {

  margin-top: 10px;

  @media (max-width: 600px) {
    position: relative;
    left: 70%;
    margin-top: 0px;
  }

  @media (max-width: 490px) {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    left: 0;

  }


}

.scheduler-card-template {

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 490px) {

    flex-direction: column;

  }

}

.scheduler-card-template-child-1 {
  @media (max-width: 490px) {
    margin-bottom: 25px;
    margin-top: 15px;
    display: flex;
    justify-content: center;

  }
}

.scheduler-card-template-child-2 {
  margin-left: 15px;

}

.scheduler-image {
  margin-top: 5px;
  width: 75px;
  height: 65px;
  border-radius: 6px;

  @media (max-width: 490px) {
    width: 190px;
    height: 150px;

  }

}


.find-camera-card-container {

  width: 100%;
  height: 300px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  overflow-y: scroll;
  padding-right: 15px;
  background-color:#F1F5F7 ;
  padding-left: 15px;
  padding-top: 5px;
  border-radius: 5px;

}

// .find-camera-card-container::-webkit-scrollbar {
//   display: none;
// }


.find-camera-card-container::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.find-camera-card-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(223, 223, 223); 
  border-radius: 10px;
}
 
/* Handle */
.find-camera-card-container::-webkit-scrollbar-thumb {
  background:#d8d8d8; 
  border-radius: 10px;
}
.find-camera-card-container {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.find-camera-first-slide {
  display: flex;
  gap: 150px;
  @media (max-width: 1200px) {
    flex-direction: column;
    padding-right: 80px;
    padding-left: 80px;
    
  }
  @media (max-width: 990px) {
    
   padding-left: 0;
   padding-right: 0;
  }

  @media (max-width: 500px) {
    
    gap: 30px;
  }
 

  

}

.find-camera-first-slide-container {
  height: 300px;

  @media (max-width: 1200px) {
    height: 0px;
  }
}



.camera-layout-button{
  margin-top: 10px;
  display: block;

  @media (max-width: 600px) {
    display: none;
  }
}



.dashboard-percentage{
  margin-right: 10px;
  background-color: #D6F3EA;
  padding-right: 3px;
  border-radius: 5px;
}


.dashboard-button-group-style{

width: 95px;
height: 40px;



}
.dashboard-button-group-style:active{
  color: red;
}



.no-camera-lottie{
  display: block;
  @media (max-width: 600px) {
    display: none;
  }
}


.no-camera-lottie2{
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
}


.graph-heading-container{
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
  flex-direction: column;
  margin-bottom: 10px;

  }
}

.average-count-card{
  position: absolute;
  top: 60px;
  left: 45%;
  min-width: 200px;
  height:38px ;
  border:1px solid #D8DDE1 ;
  border-radius:10px ;
  box-shadow: 1px 3px 4px #D8DDE1;

  @media (max-width: 1472px) {
    position: static;
    top: 0;
    left: 0;
  }
}
.average-count-card-responsive{
  display: none;
  @media (max-width: 1472px) {
    display: block;
  }
}


.month-week-today-subcontainer{
  display: flex;
  justify-content: center;

 
  @media (max-width: 600px) {
    justify-content: flex-start;
    padding-left: 15px;
  }

}

.month-week-today-container{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
  }
}


.bar-graph-charts{
  margin-top: 20px;
  @media (max-width: 850px) {
    margin-top: 66px;
  }
}
.position_center_class{
  position: absolute;
  top:50%;
  left: 50%;
transform: translate(-50%,-50%);
}


.msg_notificatins::-webkit-scrollbar {
  width: 7px;
}



.msg_notificatins{
  background-color: #fdfdfd;
}



/* Track */
.msg_notificatins::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(223, 223, 223); 
  border-radius: 10px;
}
 
/* Handle */
.msg_notificatins::-webkit-scrollbar-thumb {
  background:#d8d8d8; 
  border-radius: 10px;
}


.notification-card{
  width:100;
  user-select: none;
  min-height: 75px;
  padding: 5px ;
  border-bottom: 1px solid #E0E0E0;
  cursor: pointer;
  &:hover{
    box-shadow:0 2px 6px 0 rgba(0, 0, 0, 0.1)
  }
  
}


.product-validation-container{
  width: 100%;
  min-height: 100vh;
  background-color: #252B3B;

}

.product-id{

  position: absolute;
  transform: translate(-50%,-40%);
  top: 40%;
  left: 50%;
  width: 80%;
  height: 40vh;

}

.username-password-main-container{
  position: absolute;
  transform: translate(-50%,-20%);
  top: 20%;
  left: 50%;
  width: 80%;
  height: 40vh;
}

.username-password-container{
  width:45%;

  @media (max-width: 900px) {
    width:100%;
  }
}

.loading-container{
  position: absolute;
  transform: translate(-50%,-35%);
  top: 35%;
  left: 50%;
  width: 80%;
  height: 40vh;

}
.font-style{
  color: white;
  font-size: 12px;
}


.site-dropdown-container{
  max-height: 250px;
  overflow-y: scroll;
  padding: 10px;

}

.site-dropdown-container::-webkit-scrollbar {
  // width: 6px;
  display: none;
}
.site-dropdown-container{
  background-color: #fdfdfd;
}

/* Track */
.site-dropdown-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(223, 223, 223); 
  border-radius: 10px;
}

/* Handle */
.site-dropdown-container::-webkit-scrollbar-thumb {
  background:#d8d8d8; 
  border-radius: 10px;
}

.zone_table.td{
  padding-bottom:8px
}
.zone_table{
  width:80%;
}

.custom-color-button{
  background-color: #1976D2;
  border-color: #1976D2;
}

.input-field-style{
  min-width: 500px;
  background-color:#D9D9D9 ;

  @media (max-width: 550px) {
    width:300px;
  }
  
}


.input-field-style2{
  min-width: 500px;
  background-color:#D9D9D9 ;
  margin-bottom: 10px;
  @media (max-width: 550px) {
    width:300px;
  }
  
}

.modal-footer{
  padding-right: 0!important;
  padding-bottom: 0 !important;
}



.sensor_details_table{
  font-size: small;
  font-weight: bold;
}