
@keyframes ldio-o9d20oe2cwi {
    0% { opacity: 1 }
    100% { opacity: 0 }
  }
  .ldio-o9d20oe2cwi div {
    left: 20px;
    top: 10px;
    position: absolute;
    animation: ldio-o9d20oe2cwi linear 0.8695652173913042s infinite;
    background: #dcdcdc;
    width: 3px;
    height: 9px;
    border-radius: 0.45px / 0.45px;
    transform-origin: 1.5px 17.5px;
  }.ldio-o9d20oe2cwi div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.7971014492753623s;
    background: #dcdcdc;
  }.ldio-o9d20oe2cwi div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -0.7246376811594203s;
    background: #dcdcdc;
  }.ldio-o9d20oe2cwi div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.6521739130434783s;
    background: #dcdcdc;
  }.ldio-o9d20oe2cwi div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.5797101449275363s;
    background: #dcdcdc;
  }.ldio-o9d20oe2cwi div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.5072463768115941s;
    background: #dcdcdc;
  }.ldio-o9d20oe2cwi div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.43478260869565216s;
    background: #dcdcdc;
  }.ldio-o9d20oe2cwi div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.36231884057971014s;
    background: #dcdcdc;
  }.ldio-o9d20oe2cwi div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.2898550724637681s;
    background: #dcdcdc;
  }.ldio-o9d20oe2cwi div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.21739130434782608s;
    background: #dcdcdc;
  }.ldio-o9d20oe2cwi div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.14492753623188406s;
    background: #dcdcdc;
  }.ldio-o9d20oe2cwi div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.07246376811594203s;
    background: #dcdcdc;
  }.ldio-o9d20oe2cwi div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    background: #dcdcdc;
  }

  .ldio-o9d20oe2cwi {
    width: 44px;
    height: 44px;
    position: relative;
    transform: translateZ(0) scale(0.44);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-o9d20oe2cwi div { box-sizing: content-box; }

  
  