@import "../../../assets/scss/theme.scss";

.apexcharts-canvas {
  text {
    fill: $text-muted !important;
  }
}

.circle{
  border: solid 3px;
  background: #fff;
  width: 165px;
  height: 165px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  line-height: 0;
  position: relative;

}
.main-circle{
border: solid 3px;
background: #fff;
width: 165px;
height: 165px;
border-radius: 50%;
-moz-border-radius: 50%;
-webkit-border-radius: 50%;
line-height: 0;
position: relative;

}

.circle-text{
  
  position: absolute;
  bottom: 23%;
  width: 100%;
  font-size: 12px;
  text-align: center;
  font-weight: 900;
  
}
.circle-icon{
  position: absolute;
  bottom: 38%;
  width: 100%;
  
  text-align: center;
}

.circle-main{
  
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      
  
}

.circle-maintext{
  position: absolute;
  bottom: 58%;
  width: 100%;
  text-align: center;
  font-size: 18px;
  
  font-weight: bolder;

}

.subcircle-maintext{

border: solid 3px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  text-align: center;
  
  font-size: 16px;
  padding: 15px;
  display: flex;
  height: 165px;
  width: 165px;
  font-weight: bolder;
  


  
  
 
}

.circle_container{
  // display: grid; 
  // grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  // gap: 5px 5px; 
  // align-items: center;
  display: flex;
  gap: 35px;
  justify-content: center; 
  flex-wrap: wrap;

}

.felx-heading{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: right;
  position: relative;
  top:-8.5%;
	align-items: stretch;
	align-content: stretch;
}


